import React, { Fragment } from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';
import { useSystem } from '../hooks/useSystem';
import { Text } from '../components'

const DiscordLink = () => <a href="/discord" target="_blank" rel="noopener noreferrer">discord</a>

export const Download = () => {
    const system = useSystem()
    return (
        <Fragment>
            <Header inverted as="h1">{(navigator.language.startsWith('pt')) ? "Comece Agora!" : "Get Started Now!"}</Header>
            <Text colorStrengh>
                <p>{(navigator.language.startsWith('pt')) ? "Naruto Arena Next Generations é um APP multiplataforma disponível para:" : "Naruto Arena Next Generations is a multiplatform APP available for:"}</p>
                <p>{(navigator.language.startsWith('pt')) ? "Windows, Linux, Mac e Android" : "Windows, Linux, Mac and Android"}</p>
                <p>{(navigator.language.startsWith('pt')) ? "Você precisa baixar o APP para jogar" : "You have to download the APP to be able to play it"}</p>
                <p>{(navigator.language.startsWith('pt')) ? "Por favor reporte bugs em nosso" : "Please report any bug you may encounter on our"} <DiscordLink />!</p>
            </Text>
            <br />
            <br />

            {system.url ?
                <Button inverted icon basic labelPosition='left' size="huge" as="a" href={system.url} download="na">
                    <Icon name={system.os} />
                        Download
                        Naruto Arena
                </Button> :
                <Text colorStrengh>{(navigator.language.startsWith('pt')) ? "Seu Sistema Operacional não é suportado. Visite nosso" : "Your OS is not supported yet. Check out our"} <DiscordLink /> {(navigator.language.startsWith('pt')) ? "para mais informações" : "for more info."}</Text>
            }

        </Fragment>
    )
}
