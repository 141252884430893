import React, { Fragment, useEffect, useState } from 'react'
import { Button, Icon, Grid } from 'semantic-ui-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FeaturedVideo } from '../components/Video/FeaturedVideo'
import { useSystem } from '../hooks/useSystem';
// @ts-ignore
import SnowStorm from 'react-snowstorm';



export const Home = () => {
    const system = useSystem()
    const [isXmas, setXmas] = useState(false);
    useEffect(()=> {
        const mnth = new Date().getMonth()
        if(mnth === 11 || mnth === 0) {
            setXmas(true)
        }
    }, [])
    return (
        <Fragment>
            {isXmas && <SnowStorm />}
            <Carousel autoPlay interval={5000} infiniteLoop showThumbs={false}>
                <div>
                    <img alt="" src={"/assets/battle-screen-beta"+((navigator.language.startsWith('pt')) ? "_pt" : "")+".png"} />
                    <p className="legend">{(navigator.language.startsWith('pt')) ? "Bem-Vindo a Naruto Arena Next Generations!" : "Welcome to Naruto Arena Next Generations!"}</p>
                </div>
                <div>
                    <img alt="" src={"/assets/matchup-screen-beta"+((navigator.language.startsWith('pt')) ? "_pt" : "")+".png"} />
                    <p className="legend">{(navigator.language.startsWith('pt')) ? "Temos uma nova aparência" : "We've got a fresh new look"}</p>
                </div>
                <div>
                    <img alt="" src={"/assets/character-selection-beta"+((navigator.language.startsWith('pt')) ? "_pt" : "")+".png"} />
                    <p className="legend">{(navigator.language.startsWith('pt')) ? "E vasta quantidade de personagens familiares" : "And plenty of characters you may be familiar with"}</p>
                </div>
            </Carousel>
            <br />
            <Grid columns={2} stackable>
                <Grid.Row>
                    <Grid.Column>
                    {system.url && <Button inverted icon basic labelPosition='left' size="huge" as="a" href={system.url} download="na">
                            <Icon name={system.os} />
                                    Download
                        </Button>}
                    </Grid.Column>
                    <Grid.Column>
                    <Button inverted icon basic labelPosition='left' size="huge" as="a" href="/discord" target="_blank" rel="noopener noreferrer">
                            <Icon name="discord" />
                                    {(navigator.language.startsWith('pt')) ? "Junte-se ao Discord" : "Join our Discord"}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br />
            <FeaturedVideo />
        </Fragment>
    )
}
