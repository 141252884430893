import React, { ReactChild, ReactChildren } from 'react'
import { MediaContextProvider } from './Media';
import { MobileContainer } from './MobileContainer';
import { DesktopContainer } from './DesktopContainer'

interface NavbarProps {
  children: ReactChild | ReactChildren;
}

export const NavbarProvider = ({children} : NavbarProps) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)