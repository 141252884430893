import React, { Fragment, ReactChild, ReactChildren } from 'react';
import 'semantic-ui-css/semantic.min.css'
import { Switch, Route } from "react-router-dom";
import { NavbarProvider, Footer } from './components';
import { Home, Download} from './views'
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';
import { LegalDisclamer } from './views/LegalDisclamer';

const innerStyle = {
  padding: 25
}

const App = () => {
  return (
    <Switch>
      <Route exact path="/" >
        <Homepage>
          <Base>
            <Home />
          </Base>
        </Homepage>
      </Route>
      <Route exact path="/download" render={() => <Pages><Download /></Pages>} />
      <Route path="/legal-disclamer" render={() => <Pages><LegalDisclamer /></Pages>} />
       <ExternalLinks />
    </Switch>
  );
}
interface BaseProps {
  children: ReactChild | ReactChildren;
}
const Base = ({ children }: BaseProps) => (
  <NavbarProvider>
    <Fragment>
      <div>
        <Container text >
          <div style={innerStyle}>
            {children}
          </div>
        </Container>
      </div>
      <Footer />
    </Fragment>
  </NavbarProvider>
)

const Homepage = styled.div`
  text-align: center;
  height: auto;
  background-image: url('/assets/back2.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #171e22;
`

const Wrapper = styled.div`
  text-align: center;
  height: auto;
  background-color: #171e22;
`
const Pages = ({ children }: BaseProps) => (
  <Wrapper>
    <Base>
      {children}
    </Base>
  </Wrapper>
)

const ExternalLinks = () => (
  <Fragment>
    <Route
      path="/patreon"
      component={() => {
        global.window && (global.window.location.href = 'https://www.patreon.com/join/arenagames');
        return null;
      }}
    />
    <Route
      path="/download/mac"
      component={() => {
        global.window && (global.window.location.href = 'https://rb.gy/fjspb0');
        return null;
      }}
    />
    <Route
      path="/download/windows"
      component={() => {
        global.window && (global.window.location.href = 'https://get.arena-game.app/ejL');
        return null;
      }}
    />
    <Route
      path="/download/windows-x86"
      component={() => {
        global.window && (global.window.location.href = 'https://get.arena-game.app/xk1');
        return null;
      }}
    />
    <Route
      path="/download/linux-x86"
      component={() => {
        global.window && (global.window.location.href = 'https://rb.gy/3u7xy5');
        return null;
      }}
    />
    <Route
      path="/download/linux"
      component={() => {
        global.window && (global.window.location.href = 'https://get.arena-game.app/BlQ');
        return null;
      }}
    />
    <Route
      path="/download/android"
      component={() => {
        global.window && (global.window.location.href = 'https://rb.gy/8ulaek');
        return null;
      }}
    />
    <Route
      path="/discord"
      component={() => {
        global.window && (global.window.location.href = 'https://discord.gg/H3pw2dsXwU');
        return null;
      }}
    />
  </Fragment>
)


export default App;
