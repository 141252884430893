import React, { Fragment } from "react"
import {  Header } from "semantic-ui-react"
import { Text } from '../components'

export const LegalDisclamer = () => {
    return (
        <Fragment>
            <Header inverted as="h1">Legal Disclamer</Header>
            <Text textAlign="left">
                    <p>Naruto Arena is a website made by fans, and do not have any relation with any series or characters cited, as well as any participation in any stage of production.</p>
                    <p>This site is not meant to infringe any of the copyrights, it is simply here to encourage the continuing popularity of Japanese Animation, and for many more young viewers to watch it. </p>
                    <p>Naruto Arena was developed by a team of fans of Naruto, being a non-profit project, and may suffer changes at any time without previous notice. All the rights realated to the game system Naruto Arena, as well as the use of the software and its database, are properties of Naruto Arena, as stated on Art. 1ยบ e 2ยบ on Law No. 9.609 of February 19, 1998 on the Protection of Intellectual Property of Software. </p>
                    <p>All images, logos, names are the sole property of Masashi Kishimoto, and the use of screenshots from characters and eventual references to the series and do not constitute violation of copyright owners series Art. 46, incise VIII from Brazillian Law of Intellectual Property Law No. 9.610 of February 19, 1998 on Copyright and Neighboring Rights and art. 13 from Trade Related Aspects of Intellectual Property Rights</p>
                    <p>For all related matters concerning aspects upon the server which specific media is made available, information from this server resides on a computer system funded by the companies and/or parties associated with it. The use of this system may be, and is, monitored for computer security purposes. Any unauthorized access (hacking) to this system is prohibited and is subject to criminal and civil penalties under Federal Laws including but not limited to art. 186, 187 and 927, all the Brazilian Civil Code of 2002.</p>
                    <p>Under no circumstances will we be held responsible or liable, directly or indirectly for for any loss or damage that is caused or alleged to have been caused to you in connection with your use of, or reliance on, any content, goods or services available on any other site.</p>
                    <p>Any fundraising or donations to Naruto Arena will go towards covering server costs and improving the game. All donations are personal and not transferable, meaning they cannot be transferred to another account or registered player in the game</p>
                    <p>By continuing to access Naruto Arena, you expressly agree to all warnings and conditions mentioned on the site, as well as to all the terms above, further stating that you meet the requirements established in the Law on the content and use of this site.</p>
            </Text>
        </Fragment>
    )
}