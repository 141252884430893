import React, { Fragment } from 'react';
import { Divider, Header, Embed, Icon } from 'semantic-ui-react';

export const FeaturedVideo = () => (
    <Fragment>
        <Divider horizontal section>
            <Header as='h4' inverted>
                <Icon name='video' />
                {(navigator.language.startsWith('pt')) ? "Video em Destaque" : "Featured Video"}
            </Header>
        </Divider>
        <Embed
            id={(navigator.language.startsWith('pt')) ? 'jczHfAd_RIo' : 'RsqzgGhZwQA'}
            source='youtube'
            defaultActive
        />
    </Fragment>
)
